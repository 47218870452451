import * as React from "react"
import { Link, graphql } from "gatsby"

import Footer from "@/components/footer-one"
import Layout from "@/components/layout"
import PageBanner from "@/components/page-banner"
import MenuContextProvider from "@/context/menu-context"
import SearchContextProvider from "@/context/search-context"
import HeaderOne from "@/components/header-one"

const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  // const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next } = data
  // const tagHeads = post.frontmatter.tagHead
  const tagLinks = post.frontmatter.tagLink
  const hashTags = post.frontmatter.hashTag

  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="Blog Details">
          <HeaderOne />
          <PageBanner title={post.frontmatter.title} name="Blog" />
          <div className="container">
            <div className="row">
              <article
                className="blog-post"
                itemScope
                itemType="http://schema.org/Article"
              >
                <header>
                  <p></p>
                  {/* <h1 itemProp="headline">{post.frontmatter.title}</h1> */}
                  <p>{post.frontmatter.date}</p>
                </header>
                <section
                  dangerouslySetInnerHTML={{ __html: post.html }}
                  itemProp="articleBody"
                />
                {/* <section className='d-flex tags'>
                  { tagHeads !== null ?
                  tagLinks.map((tagLink, index) => (
                    <Link to={tagLink} target="_blank" key={index}>
                      <h6>{tagHeads[index]}</h6>
                    </Link>
                  )) : ''}
                </section> */}
                <section className='d-flex tags'>
                  { hashTags !== null ?
                  hashTags.map((hashTag, index) => (
                      <p key={index}>#{hashTag}</p>
                  )) : ''}
                </section>
              </article>
            </div>
          </div>
          <div className="blog-post-nav">
            <div className="col-lg-12 col-sm-12 text-center">
              <ul>
              <li>
                  {previous && (
                    <Link to={previous.fields.slug} rel="next">
                      ← {previous.frontmatter.title}
                    </Link>
                  )}
                </li>
                <li></li>
                <li>
                  {next && (
                    <Link to={next.fields.slug} rel="prev">
                       {next.frontmatter.title} →
                    </Link>
                  )}
                </li>
              </ul>
            </div>
          </div>
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 100)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        hashTag
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
